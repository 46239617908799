<template>
  <div
    class="questions_and_answers w-full bg-[url('/images/user/bg-hoi-dap.png')] bg-no-repeat bg-cover bg-right p-6 lg:py-14 lg:pl-40"
  >
    <div class="inline-flex justify-start items-end border border-bluePrimary p-6 lg:p-8 max-md:bg-[#ffffffcf]">
      <a @click="goToQuestionListPage()" class="mr-8 text-textBlackPrimary cursor-pointer">
        <h3 class="section-title text-2xl">{{ $t('Hỏi đáp') }}</h3>
        <p class="text-sm">{{ $t('Điểm hỏi- đáp về thuận lợi thương mại.') }}</p>
      </a>
      <a
        @click="goToQuestionListPage()"
        class="bg-bluePrimary text-white w-7 h-7 inline-flex justify-center items-center cursor-pointer"
      >
        <svg class="fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
        </svg>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    goToQuestionListPage() {
      // eslint-disable-next-line no-undef
      this.$inertia.visit(route('question.list'))
    },
  },
}
</script>
